:root {
  --black: #000000;
  --darkest-gray: #30261D;
  --light-gray: #8A7969;
  --lightest-gray: #E8E5E2;
  --white : #ffffff;
  --blue: #007CBA;
  --light-blue: #D9F2FF;
  --dark-blue: #005C8A;
  --light-brown: #64513D;
  --pink: #CA003D;
  --dark-orange: #D64123;
  --low-orange: #D641231A;
  --magenta: #A50064;
  --low-magenta: #A500641A;
  --purple: #8F4899;
  --low-purple: #8F48991A;
  --light-yellow: #EEF07A;
  --light-orange: #F2D27E;
  --light-green: #BBDB83;
  --green: #84B87F;
  --dark-green: #2E8025;
  --light-red: #E57C67;
  --lightest-brown: #D1CBC5;
}