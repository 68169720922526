//products component styles

//start

.circular-progress-loader {
  width: 100%;
  display: flex;
  align-items: center;
}

//end

//add new product component styles

//start

.add-new-product {
  &__form-container {
    margin-top: 2em;
    text-align: start;
    width: 25vw;
  }

  &__assign-category {
    display: flex;
    margin-bottom: 1em;
    font-size: 1.5em;
  }

  &__buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2em;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 32%;
  }

  &__keyword-container {
    width: 100%;
  }

  &__input-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

//end

//edit product component styles

//start

.edit-product {
  &__form-container {
    margin-top: 2em;
    text-align: start;
    width: 25vw;
  }

  &__product-name {
    width: 100%;
  }

  &__assign-category {
    display: flex;
    margin-bottom: 1em;
    font-size: 1.5em;
  }

  &__actions-title {
    font-size: 1.5em;
  }

  &__assigned-connector {
    width: 100%;
    margin-top: 3em;
    background-color: var(--lightest-gray);
    padding: 0.5em;
    border-radius: 0.5em;
  }

  &__assigned-connector-title {
    color: var(--light-brown);
  }

  &__assigned-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;
  }

  &__assignees-items {
    background-color: white;
    padding: 0.2em 0.4em;
    border-radius: 0.8em;
    margin: 0.5em;
    color: var(--light-brown);
  }

  &__buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2em;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 32%;
  }

  &__keyword-container {
    width: 100%;
  }

  &__input-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

//end
