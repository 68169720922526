.DashboardMapSvg {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  //background: #023020

  &__animation {
    transition: all 0.1s ease-out;
  }

  &__svg {
    //transform: scale(1.1);
    position: relative;
  }
}
