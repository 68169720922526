//categories component style

//start

.circular-progress {
  width: 100%;
  display: flex;
  align-items: center;
}

//end



//add new category component style

//start

.container {

  &__inputs{
    margin-top: 2em;
    text-align: start;
    width: 20vw;
  }

  &__assignee-title {
    margin-bottom: 1em;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.5em;
  }

  &__buttons-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;
    justify-content: flex-end;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 27%;
  }
}

//end


//edit category component styles

//start

.edit-category-container {

  &__form-container {
    margin-top: 2em;
    text-align: start;
    width: 25vw;
  }

  &__category-name {
    width: 100%;
  }

  &__assignee-title {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;
    font-size: 1.5em;
  }

  &__actions-title {
    font-size: 1.5em;
  }

  &__assigned-products {
    width: 100%;
    margin-top: 3em;
    background-color: var(--lightest-gray);
    padding: .5em;
    border-radius: .5em;
  }

  &__assigned-products-title {
    color: var(--light-brown);
  }

  &__assignees-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;
  }

  &__assignees-items {
    background-color: white;
    padding: .2em .4em;
    border-radius: .8em;
    margin: .5em;
    color: var(--light-brown);
  }

  &__buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 2em;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 27%;
  }
}

//end