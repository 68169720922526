.custom-table__general-name2 {
  color: var(--blue);
  padding: 0 0.4em;
  font-weight: 600;
}

.custom-table {
  &__bulk-delete-button {
    display: flex;
    gap: 6px;
  }

  &__general-name {
    margin-right: 1em;
    background-color: lightblue;
    border-radius: 1em;
    padding: 0.5em;
  }

  &__general-name-item {
    background: #f6f5f4;
    padding: 0.2em 0.5em;
    border-radius: 1em;
  }

  &__general-name-span {
    color: var(--white);
    background: var(--blue);
    font-weight: 600;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0.2em 0.7em;
    border-radius: 1em;
  }

  &__general-name-discount {
    background: #a500641a;
    color: #a50064;
    padding: 0.2em 0.5em;
    border-radius: 1em;
  }

  &__general-name-promotion {
    background: #d641231a;
    color: #d64123;
    padding: 0.2em 0.5em;
    border-radius: 1em;
  }

  &__general-name-word {
    background: #8f48991a;
    color: #8f4899;
    padding: 0.2em 0.5em;
    border-radius: 1em;
  }

  &__connector-country {
    color: var(--blue);
    font-weight: 600;
  }
}
