.MuiDrawer-paperAnchorRight {
  width: 35%;
}

.connector-name__split {
  padding: 0 0.455em;
}

.connector-name {
  color: "var(--blue)";
  font-weight: 600;
}

.email-history {
  &__date-time-container {
    display: flex;
    justify-content: space-between;
    background-color: #f6f5f4;
    padding: 0.5em;
    border-radius: 0.5em;
  }

  &__date-time {
    display: flex;
    flex-direction: column;
  }

  &__date {
    color: var(--light-gray);
  }

  &__time-container {
    margin-right: 5em;
    display: flex;
    flex-direction: column;
  }

  &__time {
    color: var(--light-gray);
  }

  &__detected-container {
    display: flex;
    background-color: #f6f5f4;
    padding: 0.5em;
    border-radius: 0.5em;
    margin: 1em 0;
  }

  &__detected {
    display: flex;
    flex-direction: column;
  }

  &__detected-title {
    color: var(--light-gray);
  }

  &__detected-violations-length {
    color: var(--pink);
  }

  &__connectors-detected-title {
    font-weight: 500;
    font-size: 1.2em;
  }

  &__detections-container {
    display: flex;
    flex-wrap: wrap;
  }

  &__detections {
    background-color: white;
    padding: 0.2em;
    border-radius: 0.5em;
    margin: 0.2em;
    border: solid var(--lightest-gray) 0.1em;
  }

  &__products-detected-title {
    font-weight: 500;
    font-size: 1.2em;
  }

  &__violations-detected-title {
    font-weight: 500;
    font-size: 1.2em;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    justify-content: space-around;
    margin-top: 3em;
    align-items: flex-end;
  }
}
