//violations component styles

//start

.circular-progress-loader {
  width: 100%;
  display: flex;
  align-items: center;
}

//end


//add new violation component styles

//start

.add-new-violation {

  &__form-container {
    margin-top: 2em;
    text-align: start;
    width: 20vw;
  }

  &__titles {
    margin-top: 1.5em;
  }

  &__buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2em;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 27%;
  }

}

//end



//edit violation component styles

//start

.edit-violation {

  &__form-container {
    margin-top: 2em;
    text-align: start;
    width: 25vw;
  }

  &__text-field-container {
    margin-top: 1.5em;
  }

  &__actions-title {
    font-size: 1.5em;
  }

  &__assigned-categories {
    width: 100%;
    margin-top: 3em;
    background-color: var(--lightest-gray);
    padding: .5em;
    border-radius: .5em;
  }

  &__assigned-categories-title {
    color: var(--light-brown);
  }

  &__assignees-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;
  }

  &__assinees-items {
    background-color: white;
    padding: .2em .4em;
    border-radius: .8em;
    margin: .5em;
    color: var(--light-brown);
  }

  &__buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 2em;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 27%;
  }
}

//end