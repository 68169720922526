.date-section {
  display: flex;
  justify-content: end;
  position: relative;
}

.date-label {
  color: var(--neutrals-dark-oak, #30261d);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
  margin-bottom: 5px;
}

.header-title {
  color: #007cba;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 10px;
}

.country-section {
  background-color: white;
  border-radius: 10px;
  width: 98%;
  height: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 16px;
  box-sizing: border-box;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}

.county-table {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 30%;
  height: 90%;
  overflow: scroll;
}

.county-table::-webkit-scrollbar {
  display: none;
}

.county-table {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.country-table__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background-color: #f6f5f4;
  border-radius: 10px;
  margin-bottom: 8px;
}

.select-date {
  width: 98% !important;
}

.country-table__content {
  height: auto;
  width: 100%;
  border-radius: 5px;
  // border-left: 5px solid #e57c67;
  margin: 3px 0;
  display: flex;
  flex-direction: column;
}

// .country-table__content-text {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

.country-map {
  border-radius: 10px;
  background-color: #f6f5f4;
  display: flex;
  flex: 1;
  width: 65%;
  height: calc(100% - 32px);
}

.violation-cluster {
  background-color: white;
  border-radius: 10px;
  width: 98%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.violation-pie-cluster {
  width: 45%;
  padding: 8px;
  height: 300px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.violation-pie-cluster__list {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  height: 300px;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}
.violation-pie-cluster__chart {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 300px;
  @media screen and (max-width: 750px) {
    display: none;
  }
}

.cluster-table {
  display: flex;
  flex-direction: column;
}

.cluster-table__section {
  display: flex;
  justify-content: space-between;
  padding: 5px 5px;
}

.violation-bar-cluster {
  width: 45%;
  height: 90%;
  background-color: #f6f5f4;
  border-radius: 10px;
  @media screen and (max-width: 1200px) {
    width: calc(100% - 32px);
    margin: 8px 16px;
  }
}

.connector-section {
  width: 98%;
  background-color: white;
  height: auto;
  padding: 1rem 0;
  margin: 0 auto;
  border-radius: 10px;
}

.header-connector {
  color: #64513d;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.connector-section__container {
  display: flex;
  flex-direction: column;
  width: 97%;
  margin: 0 auto;
}

.connector-section__chart {
  background-color: #f6f5f4;
  width: 100%;
  border-radius: 7px;
  height: 270px;
}

.connector-section__table {
  margin-top: 20px;
}

.violation-overtime-cluster {
  background-color: white;
  border-radius: 10px;
  width: 98%;
  height: 300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.violation-overtime-pie-cluster {
  width: 35%;
  height: 90%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 5px;
}

.violation-overtime-bar-cluster {
  background-color: #f6f5f4;
  border-radius: 10px;
  width: 55%;
  height: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cluster-table__section-text,
.cluster-table__section-text2 {
  width: 30% !important;
}

.cluster-table__section-text2 {
  text-align: center !important;
}

.date-picker-section {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}
