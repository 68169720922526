.MuiDrawer-paperAnchorRight {
  width: 35%;
}

.result-set {

  &__date-time-container {
    display: flex;
    justify-content: space-between;
    background-color: #F6F5F4;
    padding: 0.5em;
    border-radius: .5em
  }

  &__date-time {
    display: flex;
    flex-direction: column
  }

  &__date {
    color: var(--light-gray)
  }

  &__time-container {
    margin-right: 5em;
    display: flex;
    flex-direction: column
  }

  &__time {
    color: var(--light-gray)
  }

  &__detected-container {
    display: flex;
    background-color: #F6F5F4;
    padding: .5em;
    border-radius: .5em;
    margin: 1em 0
  }

  &__detected {
    display: flex;
    flex-direction: column
  }

  &__detected-title {
    color: var(--light-gray)
  }

  &__detected-violations-length {
    color: var(--pink)
  }

  &__connectors-detected-title {
    font-weight: 500;
    font-size: 1.2em
  }

  &__detections-container {
    display: flex;
    flex-wrap: wrap
  }

  &__detections {
    background-color: white;
    padding: .2em;
    border-radius: .5em;
    margin: .2em;
    border: solid var(--lightest-gray) .1em
  }

  &__products-detected-title {
    font-weight: 500;
    font-size: 1.2em
  }

  &__violations-detected-title {
    font-weight: 500;
    font-size: 1.2em
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    justify-content: space-around;
    margin-top: 3em;
    align-items: flex-end
  }

}