.MuiOutlinedInput-notchedOutline .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none transparent !important;
}
.connector-name{
  margin-right: .5em;
  padding: .2em .8em;
  border-radius: 1em;
  color: #007CBA;
  background: #F6F5F4;
}
.add-new-template {

  &__form-container {
    margin-top: 2em;
    text-align: start;
    width: 20vw;
  }

  &__preview-container {
    width: 60%;
    display: flex;
    margin: 5em auto 0 auto
  }

  &__preview-title {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: flex-start;
    width: 100%
  }

  &__preview-box {
    padding: 1em;
    background-color: var(--lightest-gray);
    color: var(--light-gray);
    width: 700px;
    overflow: auto;
    text-align: left;
    border-radius: .5em
  }

  &__buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2em;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 27%;
  }

}


.edit-template {

  &__form-container {
    margin-top: 2em;
    text-align: start;
    width: 25vw;
  }

  &__actions-title {
    font-size: 1.5em;
  }

  &__buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 2em;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 27%;
  }
}
