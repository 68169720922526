//results component styles

//start

.table-names {
  color: var(--blue);
  font-weight: bold;
}

.circular-progress-loader {
  display: flex;
  width: 100%;
  align-items: center;
}

//end

//results detail component styles

//start

.result-detail {
  width: 40%;

  &__address-country-container {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 0.5em;
    border-radius: 0.5em;
  }

  &__address-container {
    display: flex;
    flex-direction: column;
  }

  &__address {
    color: var(--light-gray);
  }

  &__results {
    color: var(--dark-blue);
  }

  &__country-container {
    margin-right: 5em;
    display: flex;
    flex-direction: column;
  }

  &__country {
    color: var(--light-gray);
  }

  &__section-container {
    background-color: white;
    border-radius: 0.5em;
    margin-top: 1em;
    padding: 0.5em;
  }

  &__item-container {
    display: flex;
    flex-direction: column;
  }

  &__names {
    color: var(--light-gray);
  }

  &__detection-history {
    background-color: white;
    max-height: 350px;
    overflow: auto;
    border-radius: 0.5em;
    margin-top: 1em;
    padding: 0.5em;
  }

  &__detection-history-title {
    font-weight: 500;
  }

  &__detections-container {
    background-color: #f6f5f4;
    border-radius: 0.5em;
    margin-top: 1em;
  }

  &__violation-detected-container {
    display: flex;
    justify-content: space-between;
    padding: 0.5em;
  }

  &__violation-detected {
    display: flex;
    justify-content: space-between;
    width: 45%;
  }

  &__violation-detected-title {
    color: var(--light-gray);
  }

  &__violation-detected-length {
    color: var(--darkest-gray);
  }

  &__violation-detected-items {
    padding: 1em 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__violation-detected-item {
    background-color: white;
    padding: 0.2em;
    border-radius: 0.5em;
    margin: 0.2em;
    border: solid var(--lightest-gray) 0.1em;
  }
}

.result-detail-image {
  width: 50%;
  text-align: center;
  height: 50%;
  &__image {
    width: 50%;
  }
}

.desc__section {
  width: 100%;
}

.rsult-set-corelation-id {
  color: #444;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

//end
