//connectors component styles

//start

.circular-progress-loader {
  width: 100%;
  display: flex;
  align-items: center
}

//end


//add new connector component styles

//start

.add-new-connector {

  &__form-container {
    margin-top: 2em;
    text-align: start;
    width: 25vw
  }

  &__assigned-title {
    display: flex;
    margin-bottom: 1em;
    font-size: 1.5em
  }

  &__buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2em
  }

  &__buttons {
    display: flex;
    justify-content: space-evenly;
    width: 27%
  }

  &__keyword-container {
    width: 100%;
  }

  &__input-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

//end


//edit connector component styles

//start

.edit-connector {

  &__form-container {
    margin-top: 2em;
    text-align: start;
    width: 25vw
  }

  &__text-field {
    width: 100%;
  }

  &__assigned-title {
    display: flex;
    margin-bottom: 1em;
    font-size: 1.5em
  }

  &__run-handler {
    width: 100%;
    margin: 2em 0
  }

  &__status-title {
    margin-bottom: 1em;
  }

  &__last-run-title {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 1em
  }

  &__status-section {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin-bottom: 1em
  }

  &__actions-title {
    font-size: 1.5em;
  }

  &__buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2em
  }

  &__buttons {
    display: flex;
    justify-content: space-evenly;
    width: 27%
  }
}

//end