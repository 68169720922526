.login-page {

  &__left-side-container {
    background-color: var(--lightest-brown);
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
  }

  &__logo {
    width: 10em;
  }

  &__title {
    color: var(--light-brown);
    font-weight: 200
  }

  &__subtitle {
    font-size: 2em;
    color: var(--light-brown);
    font-weight: bold
  }

  &__form-container {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column
  }
}